<template>
  <b-row>
    <!-- Importacion de empleados -->
    <b-col
      cols="12"
      lg="4"
      md="4"
      sm="6"
    >
      <BlockCard
        button="Importar"
        description="Importar empleados desde un archivo .csv"
        title="Importación de empleados"
        icon="UsersIcon"
        @action="importEmpleados = true"
      />
    </b-col>
    <importar-empleados
      :show="importEmpleados"
      @closeModal="importEmpleados = false"
    />

    <!-- Variables de entorno -->
    <b-col
      cols="12"
      lg="4"
      md="4"
      sm="6"
    >
      <BlockCard
        button="Configurar"
        description="Configurar las variables de entorno utilizadas en el sitio web"
        title="Variables de entorno"
        icon="SettingsIcon"
      />
    </b-col>

    <b-col
      cols="12"
      lg="4"
      md="4"
      sm="6"
    >
      <BlockCard
        button="Hacer copia"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        title="Copias de seguridad"
        icon="DatabaseIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import ImportarEmpleados from '@/views/system/configuracion/ImportarEmpleados.vue'
import BlockCard from '@/components/BlockCard.vue'

export default {
  components: {
    BCol,
    BRow,

    ImportarEmpleados,
    BlockCard,
  },
  data() {
    return {
      importEmpleados: false,
    }
  },
}
</script>

<style></style>
