<template>
  <b-modal
    v-model="modal"
    hide-footer
    title="Importar Empleados"
    @close="close"
  >
    <p>Importe empleados desde un archivo .csv</p>
    <b-form-file
      v-model="file"
      :state="Boolean(file)"
      placeholder="Elija un archivo o suéltelo aquí..."
      drop-placeholder="Suelta el archivo aquí..."
      browse-text="Navegar"
      accept=".csv"
    />
    <b-button
      class="mt-1 mr-1"
      variant="primary"
      @click="importar()"
    >Importar</b-button>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormFile } from 'bootstrap-vue'

import store from '@/store'
import empleadoStoreModule from '@/views/system/empleado/empleadoStoreModule'
import { onUnmounted } from '@vue/composition-api'

export default {
  components: {
    BModal,
    BButton,
    BFormFile,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
      file: null,
    }
  },
  watch: {
    show() {
      this.modal = this.show
    },
  },
  methods: {
    close() {
      this.modal = false
      this.$emit('closeModal')
    },
    importar() {
      store.dispatch('app-empleado/importEmpleados', this.file)
        .then(() => {
          this.close()
        })
    },
  },
  setup() {
    const EMPLEADO_APP_STORE_MODULE_NAME = 'app-empleado'

    // Register module
    if (!store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.registerModule(EMPLEADO_APP_STORE_MODULE_NAME, empleadoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLEADO_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
